import React from "react"
import PageHeader from "../../components/hero"
import Header from "../../components/internationalComponents/header"
import Footer from "../../components/internationalComponents/footer"
import Seo from "../../components/seo"
import IntForm from "../../components/internationalComponents/intForm"

const IntContact = () => {
    const intKeywords = ["jamstack", "japan", "Tokyo", "gatsby.js", "gatsby", "headless cms", "website", "web design"]
    return (
        <>
            <Seo title="Contact" 
                 description="monotein provides a website development service in Japan. Our website leads your business to success through increasing traffics and efficient SEO strategy"
                 keywords={intKeywords}
            />
            <Header page="InternationalContact" />
            <div className="int-contact-root">
                <PageHeader page="IntContact"/>
                <div className="container">
                    <IntForm />
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default IntContact